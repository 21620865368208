<script lang="ts">
  import type { LangType } from '@customTypes/newscraft'
  import { getQueryNameFromUrl, useTranslations } from '@i18n/utils'
  import Icon from '@iconify/svelte'
  export let lang: LangType

  const t = useTranslations(lang)

  let keyword = ''

  function navigateToSearchPage() {
    window.location.href = `/${lang}/search/?keyword=${keyword}`
  }
</script>

<search>
  <form
    class="my-6"
    on:submit|preventDefault={() => navigateToSearchPage()}
  >
    <label
      for="search"
      class="mb-1 block text-slate-600"
      >{t('search')}
      <Icon
        icon="mdi:magnify"
        class="inline-block size-6"
      />
    </label>

    <div class="relative">
      <input
        type="search"
        id="search"
        bind:value={keyword}
        class="block h-12 w-full rounded-lg border border-slate-200 p-4 text-slate-900 shadow focus:border-brandBlue"
      />
      <button
        type="submit"
        class="absolute end-0 top-0 h-full rounded-e-lg border border-brandBlue bg-brandBlue p-2.5 text-sm font-medium text-white hover:bg-indigo-800 focus:outline-none focus:ring-4 focus:ring-indigo-300"
      >
        <Icon
          icon="mdi:magnify"
          class="inline-block size-6"
        />
        <span class="sr-only">{t('search')}</span>
      </button>
    </div>
  </form>
</search>
