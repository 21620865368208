<script lang="ts">
  import { Drawer, CloseButton, Sidebar, SidebarGroup, SidebarItem, SidebarWrapper } from 'flowbite-svelte'
  import { sineIn } from 'svelte/easing'
  import Icon from '@iconify/svelte'
  import type { LangType } from '@customTypes/newscraft'
  import { useTranslations } from '@i18n/utils'
  import Search from './Search.svelte'
  import mkLogo from '@assets/logo/logo-en.svg'
  import knlLogo from '@assets/logo/knl-logo-blue.svg'

  export let lang: LangType

  const t = useTranslations(lang)

  const menuList = {
    ms: [
      {
        label: `${t('news')} ${t('latest')}`,
        href: '/ms/latest/news/',
        icon: 'mdi:newspaper-variant-outline'
      },
      {
        label: t('featured'),
        href: '/ms/series/featured/',
        icon: 'mdi:book-heart-outline'
      },
      {
        label: t('columns'),
        href: '/ms/latest/columns/',
        icon: 'mdi:format-quote-open'
      },
      {
        label: t('comments'),
        href: '/ms/tag/ulasan/',
        icon: 'mdi:comment-quote'
      },
      {
        label: t('kiniguide'),
        href: '/ms/tag/kiniguide/',
        icon: 'mdi:map-marker-question'
      },
      {
        // @ts-ignore
        label: t('sports'),
        href: '/ms/latest/sukan/',
        icon: 'mdi:soccer'
      },
      {
        // @ts-ignore
        label: t('hiburan'),
        href: '/ms/latest/hiburan/',
        icon: 'mdi:drama-masks'
      },
      {
        label: t('kini-news-lab'),
        href: 'https://newslab.malaysiakini.com/ms',
        icon: 'knl'
      },
      {
        label: t('letters'),
        href: '/ms/latest/letters/',
        icon: 'mdi:email'
      },
      {
        label: t('video'),
        href: 'https://www.kinitv.com/',
        icon: 'mdi:video-vintage'
      },
      {
        label: t('photos'),
        href: '/ms/latest/photos/',
        icon: 'mdi:camera'
      },
      {
        label: t('cartoonkini'),
        href: '/ms/cartoon/',
        icon: 'mdi:draw'
      }
    ],
    zh: [
      {
        label: t('latest'),
        href: '/zh/latest/news/',
        icon: 'mdi:newspaper-variant-outline'
      },
      {
        label: t('featured'),
        href: '/zh/series/featured/',
        icon: 'mdi:book-heart-outline'
      },
      {
        label: t('opinions'),
        href: '/zh/latest/columns/',
        icon: 'mdi:format-quote-open'
      },
      {
        label: t('comments'),
        href: '/zh/tag/今评论/',
        icon: 'mdi:comment-quote'
      },
      {
        label: t('kiniguide'),
        href: '/zh/tag/今分析/',
        icon: 'mdi:map-marker-question'
      },
      {
        label: t('malaysianskini'),
        href: '/zh/tag/今人物/',
        icon: 'mdi:account-group-outline'
      },
      {
        label: t('kini-news-lab'),
        href: `https://newslab.malaysiakini.com/${lang}`,
        icon: 'knl'
      },
      {
        label: t('letters'),
        href: '/zh/latest/letters/',
        icon: 'mdi:email'
      },
      {
        label: t('video'),
        href: 'https://www.kinitv.com/cn',
        icon: 'mdi:video-vintage'
      },
      {
        label: t('photos'),
        href: '/zh/latest/photos/',
        icon: 'mdi:camera'
      },
      {
        label: t('cartoonkini'),
        href: '/zh/cartoon/',
        icon: 'mdi:draw'
      }
      // {
      //   label: t('kiniMorningBrief'),
      //   href: '/zh/tags/newsletters/',
      //   icon: 'mdi:email-newsletter'
      // }
    ],
    en: [
      {
        label: `${t('latest')} ${t('news')}`,
        href: '/en/latest/news/',
        icon: 'mdi:newspaper-variant-outline'
      },
      {
        label: t('featured'),
        href: '/en/series/featured/',
        icon: 'mdi:book-heart-outline'
      },
      {
        label: t('opinions'),
        href: '/en/latest/columns/',
        icon: 'mdi:format-quote-open'
      },
      {
        label: t('kiniguide'),
        href: '/en/tag/kiniguide/',
        icon: 'mdi:map-marker-question'
      },
      {
        label: t('kini-news-lab'),
        href: 'https://newslab.malaysiakini.com/en',
        icon: 'knl'
      },
      {
        label: t('malaysianskini'),
        href: '/en/tag/malaysianskini/',
        icon: 'mdi:account-group-outline'
      },
      {
        label: t('letters'),
        href: '/en/latest/letters/',
        icon: 'mdi:email'
      },
      {
        label: t('video'),
        href: `https://www.kinitv.com/en`,
        icon: 'mdi:video-vintage'
      },
      {
        label: t('photos'),
        href: '/en/latest/photos/',
        icon: 'mdi:camera'
      },
      {
        label: t('cartoonkini'),
        href: '/en/cartoon/',
        icon: 'mdi:draw'
      },
      {
        label: t('kiniMorningBrief'),
        href: '/en/newsletters/',
        icon: 'mdi:email-newsletter'
      }
    ]
  }

  let hidden = true

  let transitionParams = {
    x: -320,
    duration: 200,
    easing: sineIn
  }
</script>

<button
  class="inline-flex size-8 items-center"
  on:click={() => (hidden = false)}
  aria-label={t('nav.openMenu')}
>
  <Icon
    icon="mdi:menu"
    class="mx-auto size-6 text-brandBlue"
  />
</button>

<Drawer
  transitionType="fly"
  {transitionParams}
  bind:hidden
  id="main-menu-sidebar"
  width="w-4/6 sm:w-2/5 lg:w-80"
  class=""
>
  <div class="flex items-center justify-between border-b border-slate-300 pb-5 pt-3">
    <img
      src={mkLogo.src}
      alt={t('logo.alt')}
      width="120"
      loading="lazy"
    />
    <CloseButton
      on:click={() => (hidden = true)}
      class="m-0 p-0"
    />
  </div>
  <Search {lang} />
  <Sidebar
    nonActiveClass="flex items-center rounded-lg p-2 text-base font-normal text-brandBlue hover:bg-slate-100"
    activeClass="flex items-center rounded-lg p-2 text-base font-normal text-Brandblue bg-slate-200 hover:bg-slate-100"
    ariaLabel="main-menu-sidebar"
    asideClass="w-full"
  >
    <SidebarWrapper divClass="overflow-y-auto rounded">
      <SidebarGroup>
        {#each menuList[lang] as l}
          <SidebarItem
            label={l.label}
            href={l.href}
          >
            <svelte:fragment slot="icon">
              {#if l.label === t('kini-news-lab')}
                <img
                  src={knlLogo.src}
                  alt=""
                  class="size-5"
                  loading="lazy"
                />
              {:else}
                <Icon
                  icon={l.icon}
                  class="size-5 text-brandBlue"
                />
              {/if}
            </svelte:fragment>
          </SidebarItem>
        {/each}
      </SidebarGroup>
    </SidebarWrapper>
  </Sidebar>
</Drawer>
